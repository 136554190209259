import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ReactComponent as AddIcon } from 'app/assets/plus.svg';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { paths } from 'app/routes/paths';
import { LoadableContent } from 'app/components/LoadableContent';
import { List } from 'app/components/List';
import { useInstituteSelector } from 'app/atoms/selectors';
import { EmptyState } from 'app/components/EmptyState';
import { SectionLabel } from 'app/components/Labels/SectionLabel';

import { CureItem } from './components/CureItem';
import { DiscountItem } from './components/DiscountItem';
import { EntityKind } from './hooks/useModals';
import { HeaderButton } from './components/HeaderButton';
import { ModalsProvider } from './hoc/ModalsProvider';
import { messages } from './messages';
import { useModals, useQueries, useSelectors } from './hooks';

export const SpecialOffersPage = () => {
  const { institute } = useInstituteSelector();

  const currency = institute.country.currency;

  const { discountCountLimit } = useSelectors();

  const { cures, discounts, error, isLoading } = useQueries(institute.id);
  const { t } = useTranslation();
  const history = useHistory();

  const { deleteConfirmation, maxDiscountCountReached } = useModals();

  const onCreate = (kind: EntityKind) => {
    if (!cures || !discounts) {
      return;
    }
    const totalCount = cures.length + discounts.length;

    if (totalCount >= discountCountLimit) {
      maxDiscountCountReached.toggleOpenState();
      return;
    }
    switch (kind) {
      case EntityKind.DISCOUNT:
        history.push(paths.specialOffers.discounts.create);
        break;
      case EntityKind.CURE:
        history.push(paths.specialOffers.cures.create);
        break;
    }
  };

  const onDelete = ({ kind, id }: { kind: EntityKind; id: number }) => {
    deleteConfirmation.toggleOpenState({
      kind,
      id,
    });
  };

  const onSelection = ({ kind, id }: { kind: EntityKind; id: number }) => {
    let path: string;
    switch (kind) {
      case EntityKind.DISCOUNT:
        path = paths.specialOffers.discounts.update;
        break;
      case EntityKind.CURE:
        path = paths.specialOffers.cures.update;
        break;
    }
    const location = generatePath(path, { id });
    history.push(location);
  };

  return (
    <ModalsProvider
      deleteConfirmation={deleteConfirmation}
      instituteId={institute.id}
      maxDiscountCountReached={maxDiscountCountReached}
    >
      <Helmet>
        <title>{t(messages.pageTitle())} : {institute.name}</title>
      </Helmet>
      <PageWrapper header={<PageHeader label={t(messages.pageTitle()) + " : " + institute.name }/>}>
        Vous avez la possibilité de créer 10 Promotions et 15 cures.
        <LoadableContent error={error} isLoading={isLoading}>
          <List>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <SectionLabel>
                {t(messages.discounts.sectionTitle())}
              </SectionLabel>
              <HeaderButton
                onClick={() => onCreate(EntityKind.DISCOUNT)}
                label={t(messages.discounts.create())}
                icon={<AddIcon />}
              />
            </Box>

            {discounts?.map(discount => (
              <DiscountItem
                currency={currency}
                discount={discount}
                isDeleteEnabled
                key={discount.id}
                onClick={() => {/*
                  onSelection({
                    kind: EntityKind.DISCOUNT,
                    id: discount.id,
                  });
                */}}
                onDelete={() => {
                  onDelete({
                    kind: EntityKind.DISCOUNT,
                    id: discount.id,
                  });
                }}
              />
            ))}

            {discounts?.length === 0 && (
              <EmptyState label={t(messages.discounts.emptyLabel())} />
            )}

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <SectionLabel>{t(messages.cures.sectionTitle())}</SectionLabel>
              <HeaderButton
                onClick={() => onCreate(EntityKind.CURE)}
                label={t(messages.cures.create())}
                icon={<AddIcon />}
              />
            </Box>

            {cures?.map(cure => (
              <CureItem
                currency={currency}
                cure={cure}
                isDeleteEnabled
                key={cure.id}
                onClick={() => {/*
                  onSelection({
                    kind: EntityKind.CURE,
                    id: cure.id,
                  });
                */}}
                onDelete={() => {
                  onDelete({
                    kind: EntityKind.CURE,
                    id: cure.id,
                  });
                }}
              />
            ))}
            {cures?.length === 0 && (
              <EmptyState label={t(messages.cures.emptyLabel())} />
            )}
          </List>
        </LoadableContent>
      </PageWrapper>
    </ModalsProvider>
  );
};
