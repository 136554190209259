import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { first } from 'lodash';
import { hasEntitlement } from 'utils/roles/hasEntitlement';


import { request } from 'utils/request';

import { FormWrapper } from 'app/components/Forms/FormWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { paths } from 'app/routes/paths';
import { LoadableContent } from 'app/components/LoadableContent';
import { Mode } from 'types/forms';
import { useContextModuleNavigator } from 'app/hooks';
import { User } from 'types/API/users';
import { Conditional } from 'app/components/Conditional';

import { messages } from './messages';
import { useForm, useFormData, useQueries, useSelectors } from './hooks';
import { Form as UserForm } from './Form/User';
import { Form as TillForm } from './Form/Till';
import { useInstituteSelector } from 'app/atoms/selectors';
import { string } from 'yup';

export const UserPage = () => {
  const { t } = useTranslation();
  const { institute } = useInstituteSelector();
  const { id }: { id?: string } = useParams();

  const { mode, userId } = useMemo(() => {
    const m = id ? Mode.Update : Mode.Create;
    return {
      mode: m,
      userId: id ? parseInt(id) : undefined,
    };
  }, [id]);

  const { currentInstituteId: instituteId, institutes, me } = useSelectors();

  const { userQueryState } = useQueries(userId);
  const user = userQueryState.data;
  const { popToRoot } = useContextModuleNavigator({
    context: instituteId,
    rootEndpoint: paths.users.list,
  });

  const isInstituteEditionEnabled = user?.id !== me?.id;
  const isRoleEditionEnabled = isInstituteEditionEnabled;

 

  const { canSubmit, isSubmitting, methods, onSubmit } = useForm({
    mode,
    instituteId,
    // isPasswordUpdateEnabled,
    onSuccess: popToRoot,
    user,
  });
  const isSuperAdmin = hasEntitlement('super_admin', me);



  const formData = useFormData({
    institutes
  });

  const pageTitle = useMemo(() => {
    switch (mode) {
      case Mode.Create:
        return t(messages.createPageTitle());
      case Mode.Update:
        return t(messages.updatePageTitle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // Hack
  const isTillUser = first(user?.roles) === User.Roles.ROLE_TILL;

  const ResetPasswordRequest = async () => {
    if (userId) {
      const passwordReset = await request<UserPasswordResetStructure>(`api/user/change/password`, {
        method: 'POST',
        data : { userId : userId },
      });
      console.log("Retrieved data for password update : " + passwordReset);
      return passwordReset;
    } else {
      console.log("ERROR : Cannot reset password of null user");
    }

  };

  interface UserPasswordResetStructure {
    login: string;
    password: string;
  }
  
  const ResetPassword = () => {
    ResetPasswordRequest().then((res) => {
      //should update orderId
      alert('Le nouveau mot de passe pour le compte est : ' + res?.password);
      //setLoading(false);
      //history.goBack()
    }).catch(error => {
      //setLoading(false);
      alert("Une erreur a été rencontée" + error.toString());
      // console.log(`Erreur lors de la création de la commande, error:${error}`);
      //alert('Erreur lors de la création de la commande');
    })
  }
  


  return (
    <>
      <Helmet>
        <title>{pageTitle} : {institute.name}</title>
      </Helmet>
      <PageWrapper header={<PageHeader backBehaviour label={pageTitle + " : " + institute.name} />}>
        <LoadableContent
          error={userQueryState.error}
          isLoading={userQueryState.isLoading}
        >
          <FormWrapper
            id="user-edit-form"
            methods={methods}
            onSubmit={onSubmit}
          >
            <Conditional
              condition={!isTillUser}
              trueConditionNode={
                <UserForm
                  canSubmit={canSubmit}
                  isSubmitting={isSubmitting}
                  isInstituteEditionEnabled={isInstituteEditionEnabled}
                  isRoleEditionEnabled={isRoleEditionEnabled}
                  mode={mode}
                  {...formData}
                />
              }
              falseConditionNode={
                <TillForm canSubmit={canSubmit} isSubmitting={isSubmitting} />
              }
            />
          </FormWrapper>
          {isSuperAdmin && (<button onClick={ResetPassword}>Réinitialiser le mot de passe</button>)}
        </LoadableContent>
      </PageWrapper>
    </>
  );
};
